export function parseResponse(response) {
  return response.text().then(text => {
    const data = text && tryParseResponse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        this.logout();
        document.location.reload(true);
      }

      if (response.status === 418) {
        // expected error status
        return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function tryParseResponse(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return {};
  }
}

export function toData(response) {
  return response.data;
}
